<template>
  <div class="row m-1">
    <div class="col-xl-8">
      <div class="card card-psh border">
        <div class="bg-light-primary rounded-top py-2 px-1">
          <div class="psh-header mb-0 d-flex align-items-center">
            <div class="mr-1 border-0">
              <p-icon name="bi-gear-fill" size="48px" color="primary" />
            </div>
            <div class="d-flex flex-column">
              <h4 class="card-title mb-25">
                Bundle/Multipack Fee Settings
              </h4>
              <p class="card-text mb-0">
                Configure Settings
              </p>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-md-12 mb-2">
              <p>
                This values set the fee schedule for Bundle/multipack items.
              </p>
              <p>
                <b class="text-warning">Max Charged Bundle Item Count: </b>Maximum number of items charged per Bundle or Multipack.
                Item counts above this value will not be charged.
              </p>
              <p>
                <b class="text-warning">Bundle/Multipack Extra Fee Per Item: </b> The first item in a Bundle or Multipack is covered under
                plan charge and the rest of the items will be changed for the amount of this value.
              </p>
              <b class="text-warning">For example</b> suppose that you have a bundle consisting of 8 individual items, say 3 bottles of shampoo,
              3 bottles of conditioner and 2 bottles of lotion, and given that you set 6 for Max Charged Bundle/Multipack Item Count, $0.30
              for Bundle/Multipack Extra Fee Per Item and $2 plan charge, then your customer will be charged for
              <b class="text-warning">base plan charge $2 + 6 * $0.30 = $3.80</b> In this case, you charged your client $2 for the plan charge, and 6 items at $0.30, and 1 item is free.
            </div>
            <div class="col-md-6">
              <div class="row">
                <div class="form-group col-xl-8 col-md-12">
                  <label for="text-account-no">Max Charged Bundle Item Count</label>
                  <dx-util-number-box
                    v-model="maxChargeBundleItemCount.value"
                    styling-mode="filled"
                  />
                </div>
                <div class="form-group col-xl-4 col-md-6">
                  <label for="text-account-no" />
                    <dx-util-button
                      icon="save"
                      text="Save"
                      :hint="`Save ${managedKeys.MAXCHARGEDBUNDLEITEMCOUNT.text}`"
                      type="default"
                      styling-mode="contained"
                      :element-attr="btnElementAttr"
                      @click="createOrUpdateMaxChargeBundleItemCount"
                    />
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="row">
                <div class="form-group col-xl-8 col-md-12">
                  <label for="text-account-no">Bundle/Multipack Extra Fee Per Item $</label>
                  <dx-util-number-box
                    v-model="bundleExtraFeePerItem.value"
                    format="$ #,##0.##"
                    styling-mode="filled"
                  />
                </div>
                <div class="form-group col-xl-4 col-md-6">
                  <label for="text-account-no" />
                    <dx-util-button
                      icon="save"
                      text="Save"
                      :hint="`Save ${managedKeys.BUNDLEEXTRAFEEPERITEM.text}`"
                      type="default"
                      styling-mode="contained"
                      :element-attr="btnElementAttr"
                      @click="createOrUpdateBundleExtraFeePerItem"
                    />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import tenantService from '@/http/requests/tenant/tenan.settings'
import managedKeysEnum from '@/enums/managedKeysEnum'
import valueTypeEnum from '@/enums/valueTypeEnum'
import scopeTypeEnum from '@/enums/scopeTypeEnum'

export default {
  data() {
    return {
      managedKeys: managedKeysEnum,
      maxChargeBundleItemCount: {
        id: null,
        key: managedKeysEnum.MAXCHARGEDBUNDLEITEMCOUNT.key,
        value: 0,
        tenantId: null,
        scopeType: scopeTypeEnum.TENANTSCOPEBACKEND.key,
        valueType: valueTypeEnum.INTEGER.key,
        version: null,
        referenceId: null,
      },
      bundleExtraFeePerItem: {
        id: null,
        key: managedKeysEnum.BUNDLEEXTRAFEEPERITEM.key,
        value: 0,
        tenantId: null,
        scopeType: scopeTypeEnum.TENANTSCOPEBACKEND.key,
        valueType: valueTypeEnum.BIGDECIMAL.key,
        version: null,
        referenceId: null,
      },
      btnElementAttr: {
        class: 'btn-block',
      },
    }
  },
  mounted() {
    this.getCurrentMaxChargeBundleItemCount()
    this.getCurrentBundleExtraFeePerItem()
  },
  methods: {
    // #region Shipping Up Charge Min Dollar
    getCurrentMaxChargeBundleItemCount() {
      tenantService.fetchByKey(managedKeysEnum.MAXCHARGEDBUNDLEITEMCOUNT.key).then(result => {
        const data = { ...result.data }
        if (data.id) {
          this.maxChargeBundleItemCount = {
            id: data.id,
            key: data.key,
            value: parseFloat(data.value),
            tenantId: data.tenantId,
            scopeType: scopeTypeEnum.TENANTSCOPEBACKEND.key,
            valueType: data.valueType,
            version: data.version,
            referenceId: data.referenceId,
          }
        }
      })
    },
    createOrUpdateMaxChargeBundleItemCount() {
      const maxChargeBundleItemCount = { ...this.maxChargeBundleItemCount }
      if (maxChargeBundleItemCount.id) {
        tenantService
          .update(maxChargeBundleItemCount)
          .then(result => {
            const { id, version, tenantId } = result.data.body
            this.maxChargeBundleItemCount = {
              ...this.maxChargeBundleItemCount,
              id,
              version,
              tenantId,
            }
          })
      } else {
        tenantService
          .create(maxChargeBundleItemCount)
          .then(result => {
            const { id, version, tenantId } = result.data.body
            this.maxChargeBundleItemCount = {
              ...this.maxChargeBundleItemCount,
              id,
              version,
              tenantId,
            }
          })
      }
    },
    // #endregion
    getCurrentBundleExtraFeePerItem() {
      tenantService.fetchByKey(managedKeysEnum.BUNDLEEXTRAFEEPERITEM.key).then(result => {
        const data = { ...result.data }
        if (data.id) {
          this.bundleExtraFeePerItem = {
            id: data.id,
            key: data.key,
            value: parseFloat(data.value),
            tenantId: data.tenantId,
            scopeType: scopeTypeEnum.TENANTSCOPEBACKEND.key,
            valueType: data.valueType,
            version: data.version,
            referenceId: data.referenceId,
          }
        }
      })
    },
    createOrUpdateBundleExtraFeePerItem() {
      const bundleExtraFeePerItem = { ...this.bundleExtraFeePerItem }
      if (bundleExtraFeePerItem.id) {
        tenantService
          .update(bundleExtraFeePerItem)
          .then(result => {
            const { id, version, tenantId } = result.data.body
            this.bundleExtraFeePerItem = {
              ...this.bundleExtraFeePerItem,
              id,
              version,
              tenantId,
            }
          })
      } else {
        tenantService
          .create(bundleExtraFeePerItem)
          .then(result => {
            const { id, version, tenantId } = result.data.body
            this.bundleExtraFeePerItem = {
              ...this.bundleExtraFeePerItem,
              id,
              version,
              tenantId,
            }
          })
      }
    },
  },
}
</script>

<style>

</style>
